export const ROUTES = {
  CHECKOUT: "/compra_curso",
  CHECKOUT_SUCCESS: "/pago_exitoso",
  CHECKOUT_FAILED: "/pago_fallido",
  VERIFICAR_CORREO: "/verificar_correo",
  OTP_VERIFICATION: "/verificar-codigo",
  RESTABLISH_PASSWORD: "/reestablecer-contrasena",
  FORGOT_PASSWORD:"/cambiar-contrasena",
  HOME: "/",
  NOSOTROS: "/nosotros",
  SOBRE_EL_CURSO: "/acerca",
  // BLOG: "/blog",
  // BLOG_ENTRADA: "/blog/:id",
  PRUEBA: "/prueba",
  CONTACTO: "/contacto",
  LOGIN: "/iniciar-sesion",
  REGISTRO: "/registro",
  VERIFY_EMAIL_CODE: "/verify_email_code",
  PLATAFORMA_DASHBOARD: "/cursoENARM", 
  PLATAFORMA_PLANES_ID: "/cursoENARM/planes",
  PLATAFORMA_PLANES_CONTENIDO: "/cursoENARM/planes_contenido",
  PLATAFORMA_PLANES_RESULTADOS: "/cursoENARM/resultados",
  PLATAFORMA_PLANES_SIMULADOR: "/cursoENARM/simulador",
  PLATAFORMA_PLANES_RETROALIMENTACION: "/cursoENARM/retroalimentacion",
  // PLATAFORMA_PLANES_RETROALIMENTACION: "/cursoENARM/simulador/retro/:especialidad/:id/:intento/resultados",
  PLATAFORMA_AVISO_NOVEDADES: "/cursoENARM/novedades",
  PLATAFORMA_RECURSOS: "/cursoENARM/recursos",
  PLATAFORMA_RECURSOS_ID: "/cursoENARM/recursos/:id",
  PLATAFORMA_SIMULADOR: "/cursoENARM/simuladores",
  PLATAFORMA_DOCUMENTOS: "/cursoENARM/documentos",
  PLATAFORMA_CUENTA: "/cursoENARM/MiCuenta",
  PLATAFORMA_DOCUMENTOS_GUIA: "/cursoENARM/documentos/guia",
  PLATAFORMA_DOCUMENTOS_PROGRAMA: "/cursoENARM/documentos/programa_academico",
  PLATAFORMA_AVISOS_ENARM: "/cursoENARM/documentos/avisos_enarm",
};

export const CONTACT_URLS = {
  HOME: "/",
  NOSOTROS: "/nosotros",
  SOBRE_EL_CURSO: "/acerca",
  CONTACTO: "/contacto",
  LOGIN: "/iniciar-sesion",
  REGISTRO: "/registro",
}
