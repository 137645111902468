import React from "react";

const ArrowLeft = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.1572 7.32115H4.54378L10.8962 1.89258C10.9978 1.80508 10.9361 1.64258 10.8019 1.64258H9.19655C9.12581 1.64258 9.05869 1.66758 9.00609 1.71222L2.15118 7.56758C2.0884 7.62115 2.03805 7.68739 2.00354 7.76179C1.96903 7.8362 1.95117 7.91704 1.95117 7.99883C1.95117 8.08062 1.96903 8.16146 2.00354 8.23587C2.03805 8.31027 2.0884 8.3765 2.15118 8.43008L9.04599 14.3211C9.0732 14.3444 9.10585 14.3569 9.14032 14.3569H10.8001C10.9343 14.3569 10.996 14.1926 10.8944 14.1069L4.54378 8.67829H15.1572C15.237 8.67829 15.3023 8.61401 15.3023 8.53544V7.46401C15.3023 7.38544 15.237 7.32115 15.1572 7.32115Z"
        fill="black"
        fill-opacity="0.85"
      />
    </svg>
  );
};

export default ArrowLeft;
